import colors from 'tailwindcss/colors';
import forms from '@tailwindcss/forms';
delete colors['lightBlue'];
delete colors['warmGray'];
delete colors['trueGray'];
delete colors['coolGray'];
delete colors['blueGray'];

export default {
	content: [
		'./resources/**/*.blade.php',
		'./resources/**/*.js',
		'./resources/**/*.vue',
	],
	media: false,
	theme: {
		fontSize: {
			smallest: '0.6rem',
			xs: '0.75rem;',
			sm: '0.875rem',
			base: '1rem',
			lg: '1.125rem',
			xl: '1.25rem',
			'2xl': '1.563rem',
			'3xl': '1.953rem',
			'4xl': '2.441rem',
			'5xl': '3.052rem'
		},
		colors: {
			...colors,
			transparent: 'transparent',
			white: '#fff',
			graybg: '#FBFBFB',
			black: {
				DEFAULT: '#000',
				darker: '#404B59',
			},
			primary: '#eb5757',
			secondary: '#212934',
			third: {
				DEFAULT: '#929FB0',
				darker: '#6E7A89',
				mid: '#E0E4E5',
				alt: '#D5DDE5',
				altlight: '#CDD4DA',
				sky: '#F0F4FE',
				superlight: '#F6F8F9',
				extralight: '#F8F9FA',
				ultralight: '#FCFBFD',
				terra: '#E5E7EA',
			},
			grey: {
				extraDark: '#333',
				dark: '#4f4f4f',
				darker: '#828282',
				normal: '#bdbdbd',
				lighter: '#e0e0e0',
				light: '#f2f2f2',
				tint: '#afbecc',
				btn: '#949FAE',
				'btn-hover': '#7e8692'
			},
			red: {
				DEFAULT: '#C90D32',
				normal: '#C90D32',
				hover: '#A70B2A',
				lighter: '#E40F39',
				light: '#e90000',
			},
			orange: {
				DEFAULT: '#f2994a',
				darker: '#F66615',
			},
			yellow: {
				DEFAULT: '#f2c94c',
				light: '#FDE4A3',
			},
			green: {
				dark: '#219653',
				darker: '#33995D',
				normal: '#27ae60',
				light: '#6fcf97',
				lightalt: '#67BD7C',
			},
			blue: {
				superdark: '#1F2B43',
				extradark: '#364AA2',
				darker: '#6278DB',
				dark: '#2f80ed',
				normal: '#2d9cdb',
				mid: '#96AFEB',
				light: '#56ccf2',
				superlight: '#E1E7EC',
			},
			purple: {
				dark: '#9b51e0',
				normal: '#4A60C3',
				light: '#D4DEF7',
				lighter: '#FDA3E9',
			},
			pink: {
				light: '#FCCFD8',
			},
			badge: {
				success: '#67BD7C',
				danger: '#E45C0F',
				warning: '#EAAE54',
			},

			status: {
				success: '#CFE6D2',
				danger: '#FEC4C0',
				done: '#9FCE63',
				'border-1': '#dce3f2',
				'border-2': '#b8c6e4',
				'border-3': '#94a9d8',
				'border-4': '#385492',
			},
			chat: {
				customer: '#E0E4E5',
				restaurant: '#4A60C3',
			},
			modal: {
				DEFAULT: 'rgba(33, 44, 52, 0.5)',
			},


			button: {
				bg: {
					ghost: '#FEF1F3',
					pressed: '#F8A0B1',
					'hover-text': '#FEF1F34D',
					'pressed-text': '#FEF1F3'
				},
				text: {
					normal: '#404B59',
					disabled: '#929FB0',
					label: '#6E7A89',
					'label-focus': '#6278DB',
					'label-disabled': '#AFBECC',
				},
				border: {
					normal: '#D5DDE5',
					focus: '#6278DB',
					hover: '#929FB0',
				}
			}
		},
		screens: {
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1292px',
			'2xl': '1440px',
			'3xl': '1600px',
			'fullhd': '1920px',
			'2k': '2560px'
		},
		boxShadow: {
			sm: '0 0 8px 0 rgba(4, 23, 109, 0.1)',
			table: '0 0 8px rgba(4, 23, 109, 0.1)',
			sidemenu: '0 0 0 rgba(0, 0, 0, 0.25)',
			tabshead: '0 4px 4px rgba(0, 0, 0, 0.25)',
			badge: '0px 0px 2px rgba(0, 0, 0, 0.25)',
			tabsheadsecond: '0 0 8px rgba(4, 23, 109, 0.1)',
			tabsheading: '-4px 2px 8px rgba(4, 23, 109, 0.1)',
			restaurantlogo: '0 0 8px rgba(4, 23, 109, 0.2)',
			cart: '0 -4px 8px rgba(4, 23, 109, 0.2)',
			card: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
		},
	},
	plugins: [forms],
};
