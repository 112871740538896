import Vue from 'vue';
import VueRouter from 'vue-router';
import initI18n from './i18n';
import './../sass/app.scss';
import vSelect from 'vue-select';
import { createPinia, PiniaVuePlugin } from 'pinia';
import './bootstrap';
import './components';
import Api from '@/api';
import Auth from '@/auth';
import Vuex from 'vuex';
import Meta from 'vue-meta';
import router from './routers/index';
import DashboardMain from '@/modules/shared/dashboard-main/DashboardMain.vue';
import store from './store';
import VueFlags from '@growthbunker/vueflags';
import VueCarousel from 'vue-carousel';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

const TAILWIND_CONFIG = resolveConfig(tailwindConfig);

window.Vue = Vue; // Set Vue to window object for global access
window.tailwind = TAILWIND_CONFIG;

window.api = new Api();
window.auth = new Auth();

Vue.prototype.$isMobile = function() {
	return window.innerWidth < 768;
};

Vue.use(Vuex);
Vue.use(Meta);
Vue.use(VueRouter);
Vue.component('VSelect', vSelect);

Vue.use(VueFlags, {
	iconPath: '/assets/images/icons/flags/',
});

Vue.use(VueCarousel);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

initI18n()
	.then(
		(i18n) => {
			window.i18n = i18n;

			new Vue({
				router,
				i18n,
				store,
				pinia,
				render: (h) => h(DashboardMain),
			}).$mount('#dashboard');
		});
